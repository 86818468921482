import Cookies from "js-cookie";
import { id, url } from "@/config/analytics";

function randomStr(length = 20) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const user_id = () => {
  if (!Cookies.get("serial")) {
    Cookies.set("serial", randomStr());
  }
  return Cookies.get("serial");
};
export const loadAnalyticsScript = () => {
  var script = document.createElement("script");
  script.type = "text/javascript";
  script.src = url;
  document.body.appendChild(script);
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  // if (id) {
  gtag("js", new Date());
  gtag("config", id);
  gtag("set", { user_id: user_id() }); // Set the user ID using signed-in user_id.

  // }
};
