<template>
  <main>
    <section v-if="!loading">
      <Header />
      <transition name="fade" mode="in-out">
        <router-view />
      </transition>
      <ScrollButton />
      <Footer class="mt-5" />

      <global-overlay
        ref="orderOverlay"
        :show-overlay="globalOverlay.isOpen"
        :order-type="globalOverlay.type"
        :order-id="globalOverlay.projectId"
        :overlay-type-index="globalOverlay.typeIndex"
        @closeOverlay="closeOverlay"
        :demos="globalOverlay.demos"
      />
    </section>
    <loader v-else />
  </main>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
    Header: () => import("@/components/layout/Header/index"),
    Footer: () => import("@/components/layout/Footer"),
    ScrollButton: () => import("@/components/ScrollButton"),
    loader: () => import("@/components/loader")
  },
  computed: {
    ...mapGetters(["globalOverlay"])
  },
  data() {
    return {
      loading: true
    };
  },
  methods: {
    closeOverlay() {
      this.$store.dispatch("SetGlobalOverlay", {
        type: "",
        isOpen: false
      });
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }
};
</script>
