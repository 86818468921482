<template>
  <b-form-group
    :invalid-feedback="getErrorMessages"
    :state="isValid"
    :label="labelText"
    :label-for="labelFor"
  >
    <slot
      :attrs="{ state: isValid }"
      :listeners="{ input: () => preferredValidator.$touch() }"
    />
  </b-form-group>
</template>
<script>
import { mapGetters } from "vuex";
import { singleErrorExtractorMixin } from "vuelidate-error-extractor";

export default {
  extends: singleErrorExtractorMixin,
  props: {
    labelFor: {
      type: String,
      default: ""
    },
    hideLabel: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(["serverErrors"]),
    getErrorMessages() {
      if (
        this.serverErrors !== null &&
        this.serverErrors !== undefined &&
        this.serverErrors[this.name]
      ) {
        return this.serverErrors[this.name][0] || "";
      } else {
        return this.firstErrorMessage;
      }
    },
    isValid() {
      if (this.getErrorMessages.length > 0) {
        return false;
      } else {
        return null;
      }
    },
    labelText() {
      if (!this.hideLabel) {
        return "";
      } else if (this.label.length > 0) {
        return this.label;
      } else {
        return this.$t(`input.${this.name}`);
      }
    }
  }
};
</script>
