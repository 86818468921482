import axios from "axios";
import { baseURL } from "@/config/requests";
import store from "@/store";
import cookie from 'js-cookie'
// import { getToken } from "@/utils/auth";

// create an axios instance
const service = axios.create({
  baseURL: baseURL,
  // baseURL: process.env.VUE_APP_BASE_URL, // api base_url

  timeout: 20000 // request timeout
});

// request interceptor
service.interceptors.request.use(
  config => {
    // Do something before request is sent
    if (config.headers["X-locale"] === undefined) {
      config.headers["X-locale"] = cookie.get('language') || 'ar';
    }

    // Update Request Put locale property in request data
    if (
      config.method === "put" ||
      (config.data && config.data._method && config.data._method === "put")
    ) {
      config.data.locale = store.getters.locale;
    }
    return config;
  },
  error => {
    // Do something with request error
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  function (response) {
    // Do something with response data
    store.dispatch("ClearServerErrors");
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  response => {
    // global success reponse message
    let { method } = response.config;
    if (method === "post" || method === "put" || method === "delete") {
      if (response.data.message) {
        store.dispatch("ShowNotification", {
          text: response.data.message,
          color: "success"
        });
      }
    }

    return response;
  },

  error => {

    store.dispatch("ShowNotification", {
      text: error.response.data.message,
      color: "red"
    });
    const { errors } = error.response.data;
    store.dispatch("SetServerErrors", errors);
    return Promise.reject(error);
  }
);

export default service;
