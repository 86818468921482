<template>
  <transition name="slide">
    <section v-if="showOverlay" class="global-overlay">
      <b-button
        class="button button--icon global-overlay__close"
        @click="closeOverlay"
      >
        &times;
      </b-button>
      <component
        class="mt-5"
        :is="overlayComp[overlayTypeIndex]"
        :order-type="orderType"
        :projectOrderId="orderId"
        :demos="demos && demos"
      />
    </section>
  </transition>
</template>

<script>
export default {
  name: "GlobalOverlay",

  components: {
    OverlayWizard: () => import("./OverlayWizard"),
    DemoOptions: () => import("./DemoOptions")
  },

  props: {
    overlayTypeIndex: {
      type: Number,
      default: 0
    },
    showOverlay: {
      type: Boolean,
      default: false
    },
    orderType: {
      type: String,
      default: "service"
    },
    orderId: {
      type: Number,
      default: 0
    },
    demos: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      overlayComp: ["OverlayWizard", "DemoOptions"]
    };
  },
  watch: {
    showOverlay: {
      handler(value) {
        if (value) {
          if (this.overlayTypeIndex) {
            this.$router.push("/demo");
          } else {
            this.$router.push("/order");
          }
        }
        if (process.client) {
          // process.client =>> to make suere we are in client not on server
          const body = document.body;
          if (value) {
            body.style.overflowY = "hidden";
          } else {
            body.style.overflowY = "visible";
          }
        }
      },
      immediate: true
    }
  },

  methods: {
    closeOverlay() {
      this.$router.go(-1);
      this.$emit("closeOverlay");
    }
  }
};
</script>

<style lang="scss" scoped>
.slide-enter-active {
  animation: slide-in 0.5s cubic-bezier(0.68, 0.01, 0.28, 0.96) forwards;
}

.slide-leave-active {
  animation: slide-out 0.5s cubic-bezier(0.68, 0.01, 0.28, 0.96) forwards;
}

// @keyframes slide-in {
//   from {
//     transform: translateY(100vh);
//   }
//   to {
//     transform: translate(0);
//   }
// }

// @keyframes slide-out {
//   from {
//     transform: translate(0);
//   }
//   to {
//     transform: translateY(100vh);
//   }
// }
</style>
