import Vue from "vue";

export function truncate(value, strCount = 50) {
  if (!value) {
    return "";
  }

  return value.length > strCount
    ? value.substring(0, strCount) + "..."
    : value.substring(0, strCount);
}

Vue.filter("truncate", truncate);
