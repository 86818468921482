var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"main_jumbotron",class:{ 'main_jumbotron--gradient': _vm.isGradient }},[_c('b-container',[_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{staticClass:"main_jumbotron__info",attrs:{"cols":"12","md":"7"}},[(_vm.mainHeaders.main)?_c('article',[_c('h1',{staticClass:"main-header",domProps:{"innerHTML":_vm._s(_vm.mainHeaders.main)}}),_c('p',{staticClass:"secondary-header",domProps:{"innerHTML":_vm._s(_vm.mainHeaders.secondary)}})]):(_vm.requestChecker)?_c('section',[_c('content-placeholders',{attrs:{"rounded":true}},[_c('content-placeholders-heading',{staticClass:"main_jumbotron__placeholder"})],1)],1):_vm._e(),_c('section',{staticClass:"main_jumbotron__actions"},[_c('Button',{staticClass:"button main_jumbotron-btn",class:{
              'button--filled-white': _vm.isGradient,
              'button--filled-primary': !_vm.isGradient
            },attrs:{"text":_vm.$t('button.start_project'),"hasIcon":false,"variant":"light"},on:{"click":_vm.toggleOverlay}}),_c('Button',{staticClass:"button main_jumbotron-btn",class:{
              'button--outline-white': _vm.isGradient,
              'button--outline-primary': !_vm.isGradient
            },attrs:{"to":"/about-us","text":_vm.$t('button.about_us'),"variant":"outline-light"}})],1)]),_c('b-col',{staticClass:"main_jumbotron__illust--container",attrs:{"cols":"12","md":"5"}},[_c('b-img',{staticClass:"main_jumbotron__illust--img",attrs:{"fluid":"","src":_vm.illustSrc}})],1)],1)],1),_c('global-overlay',{attrs:{"show-overlay":_vm.showOverlay},on:{"closeOverlay":function($event){_vm.showOverlay = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }