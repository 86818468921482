const getters = {
  //   token: state => state.user.token,
  //   user: state => state.user.user,
  //   restaurant: state => state.user.restaurant,
  serverErrors: state => state.errors.serverErrors,
  GlobalServices: state => state.services.services,
  GlobalSettings: state => state.settings.settings,
  //   settings: state => type => {
  //     const settings = state.settings.settings.filter(el => el.type === type)[0];
  //     if (settings) {
  //       return settings.value;
  //     }
  //   },
  settings: state => type => {
    return state.settings.settings[type];
  },
  globalOverlay: state => state.globalOverlay
  //   errgetUserInfoors: state => state.notification.errors,
  //   language: state => state.localization.locale,
  //   locale: state => state.locale.locale
  //   addRouters: state => state.permission.addRouters,
  //   permission_routers: state => state.permission.routers
};
export default getters;
