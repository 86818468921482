<template>
  <b-button class="button button--outline " v-bind="$attrs" v-on="$listeners">
    {{ text }}
    <i v-if="hasIcon" class="icon-right-arrow card--icon"></i>
  </b-button>
</template>

<script>
export default {
  name: "Button",
  props: {
    text: {
      type: String,
      required: true
    },
    hasIcon: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="scss" scoped></style>
