import request from "@/helpers/request";
import cookies from "js-cookie";
// import checkThereIsQuery from "@/helpers/checkQuery";
const localeFromCookies = cookies.get("language");
export function index(reqName, query = {}, locale = localeFromCookies) {
  return request({
    url: `api/${reqName}`,
    headers: { "X-locale": locale },
    method: "get",
    params: {
      ...query
    }
  });
}

export function showData(reqName, slug, locale = localeFromCookies) {
  return request({
    url: `api/${reqName}/${slug}`,
    headers: { "X-locale": locale },
    method: "get"
  });
}

export function storeData(reqName, data) {
  return request({
    url: `api/${reqName}`,
    method: "post",
    data
  });
}
export const updateData = (reqName, data, id, locale = localeFromCookies) => {
  return request({
    url: `api/${reqName}/${id}`,
    method: "post",
    headers: { "X-locale": locale ? locale : cookies.get("language") },
    data: { ...data, _method: "put", locale }
  });
};
export function deleteData(reqName, id) {
  return request({
    url: `api/${reqName}/${id}`,
    method: "delete"
  });
}
export function mediaUpdate(id, data) {
  return request({
    url: `api/media/${id}`,
    method: "post",
    data
  });
}
