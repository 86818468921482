import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import i18n from "@/lang";

const routes = [
  {
    path: "/",
    name: "default",
    component: () => import("../views/index.vue"),
    meta: {
      title: i18n.t("meta.home.page_title"),
      metaTags: [
        {
          name: "title",
          content: i18n.t("meta.home.title"),
        },
        {
          name: "description",
          content: i18n.t("meta.home.description"),
        },
        {
          name: "keywords",
          content: i18n.t("meta.home.keywords"),
        },
      ],
    },
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/index.vue"),
    meta: {
      title: i18n.t("meta.home.page_title"),
      metaTags: [
        {
          name: "title",
          content: 'Certificates',
        },
        {
          name: "description",
          content:'Certificates',
        },
        {
          name: "keywords",
          content: i18n.t("meta.home.keywords"),
        },
      ],
    },
  },
  {
    path: "/blog/:slug",
    name: "blogDetails",
    component: () => import("../views/blog/_slug"),
    meta: {
      title: i18n.t("meta.blog.page_title"),
      metaTags: [
        {
          name: "title",
          content: i18n.t("meta.blog.title"),
        },
        {
          name: "description",
          content: i18n.t("meta.blog.description"),
        },
        {
          name: "keywords",
          content: i18n.t("meta.blog.keywords"),
        },
      ],
    },
  },
  {
    path: "/certificates/:slug",
    name: "certificatesDetails",
    component: () => import("../views/certificates/_slug"),
    meta: {
      title: i18n.t("meta.certificates.page_title"),
      metaTags: [
        {
          name: "title",
          content: i18n.t("meta.certificates.title"),
        },
        {
          name: "description",
          content: i18n.t("meta.certificates.description"),
        },
        {
          name: "keywords",
          content: i18n.t("meta.certificates.keywords"),
        },
      ],
    },
  },
  {
    path: "/about-us",
    name: "aboutUs",
    component: () => import("../views/about-us"),
    meta: {
      title: i18n.t("meta.about_us.page_title"),
      metaTags: [
        {
          name: "title",
          content: i18n.t("meta.about_us.title"),
        },
        {
          name: "description",
          content: i18n.t("meta.about_us.description"),
        },
        {
          name: "keywords",
          content: i18n.t("meta.about_us.keywords"),
        },
      ],
    },
  },
  {
    path: "/blog",
    name: "blog",
    component: () => import("../views/blog"),
    meta: {
      title: i18n.t("meta.blog.page_title"),
      metaTags: [
        {
          name: "title",
          content: i18n.t("meta.blog.title"),
        },
        {
          name: "description",
          content: i18n.t("meta.blog.description"),
        },
        {
          name: "keywords",
          content: i18n.t("meta.blog.keywords"),
        },
      ],
    },
  },
  {
    path: "/contact-us",
    name: "contactUs",
    component: () => import("../views/contact-us"),
    meta: {
      title: i18n.t("meta.contact_us.page_title"),
      metaTags: [
        {
          name: "title",
          content: i18n.t("meta.contact_us.title"),
        },
        {
          name: "description",
          content: i18n.t("meta.contact_us.description"),
        },
        {
          name: "keywords",
          content: i18n.t("meta.contact_us.keywords"),
        },
      ],
    },
  },
  // {
  //   path: "/managers-council",
  //   name: "managersCouncil",
  //   component: () => import("../views/managers-council"),
  //   meta: {
  //     title: i18n.t("meta.mangers_council.page_title"),
  //     metaTags: [
  //       {
  //         name: "title",
  //         content: i18n.t("meta.mangers_council.title"),
  //       },
  //       {
  //         name: "description",
  //         content: i18n.t("meta.mangers_council.description"),
  //       },
  //       {
  //         name: "keywords",
  //         content: i18n.t("meta.mangers_council.keywords"),
  //       },
  //     ],
  //   },
  // },
  {
    path: "/media",
    name: "media",
    component: () => import("../views/media"),
    meta: {
      title: i18n.t("meta.media.page_title"),
      metaTags: [
        {
          name: "title",
          content: i18n.t("meta.media.title"),
        },
        {
          name: "description",
          content: i18n.t("meta.media.description"),
        },
        {
          name: "keywords",
          content: i18n.t("meta.media.keywords"),
        },
      ],
    },
  },
  {
    path: "/services",
    name: "services",
    component: () => import("../views/services"),
    meta: {
      title: i18n.t("meta.services.page_title"),
      metaTags: [
        {
          name: "title",
          content: i18n.t("meta.services.title"),
        },
        {
          name: "description",
          content: i18n.t("meta.services.description"),
        },
        {
          name: "keywords",
          content: i18n.t("meta.services.keywords"),
        },
      ],
    },
  },
  {
    path: "/services/:service_name/:id",
    name: "serviceDetails",
    component: () => import("../views/services/service-details"),
    meta: {
      title: "",
      metaTags: [],
    },
  },
  {
    path: "/store",
    name: "store",
    component: () => import("../views/store"),
    meta: {
      title: i18n.t("meta.store.page_title"),
      metaTags: [
        {
          name: "title",
          content: i18n.t("meta.store.title"),
        },
        {
          name: "description",
          content: i18n.t("meta.store.description"),
        },
        {
          name: "keywords",
          content: i18n.t("meta.store.keywords"),
        },
      ],
    },
  },
  {
    path: "/store/:slug",
    component: () => import("../views/store/project-details"),
    name: "projectDetails",
    meta: {
      title: "",
      metaTags: [],
    },
  },
  {
    path: "/plans/:service_name",
    name: "allPlans",
    component: () => import("../views/plans/index.vue"),
    meta: {
      title: i18n.t("meta.plans_title"),
      metaTags: [
        {
          name: "title",
          content: i18n.t("meta.plans.title"),
        },
        {
          name: "description",
          content: i18n.t("meta.plans.description"),
        },
        {
          name: "keywords",
          content: i18n.t("meta.plans.keywords"),
        },
      ],
    },
  },
  {
    path: "/our-works",
    name: "our-works",
    component: () => import("../views/our-works/index.vue"),
    meta: {
      title: i18n.t("meta.our_work.page_title"),
      metaTags: [
        {
          name: "title",
          content: i18n.t("meta.our_work.title"),
        },
        {
          name: "description",
          content: i18n.t("meta.our_work.description"),
        },
        {
          name: "keywords",
          content: i18n.t("meta.our_work.keywords"),
        },
      ],
    },
  },
  {
    path: "/work-details/:slug",
    name: "work-details",
    component: () => import("../views/work-details"),
    meta: {
      title: "",
      metaTags: [],
    },
  },
  {
    path: "/order",
    name: "order",
    component: () => import("../views/order"),
    meta: {
      title: i18n.t("meta.order.page_title"),
      metaTags: [
        {
          name: "title",
          content: i18n.t("meta.order.title"),
        },
        {
          name: "description",
          content: i18n.t("meta.order.description"),
        },
        {
          name: "keywords",
          content: i18n.t("meta.order.keywords"),
        },
      ],
    },
  },
  {
    path: "/demo",
    name: "demo",
    component: () => import("../views/order"),
  },
  {
    path: "/clients",
    name: "Clients",
    component: () => import("@/views/clients"),
    meta: {
      title: i18n.t("meta.clients.page_title"),
      metaTags: [
        {
          name: "title",
          content: i18n.t("meta.clients.title"),
        },
        {
          name: "description",
          content: i18n.t("meta.clients.description"),
        },
        {
          name: "keywords",
          content: i18n.t("meta.clients.keywords"),
        },
      ],
    },
  },
  {
    path: "/usage-policy",
    name: "UsagePolicy",
    component: () => import("@/views/usage-policy"),
    meta: {
      title: i18n.t("meta.usage_policy.page_title"),
      metaTags: [
        {
          name: "title",
          content: i18n.t("meta.clients.title"),
        },
        {
          name: "description",
          content: i18n.t("meta.clients.description"),
        },
        {
          name: "keywords",
          content: i18n.t("meta.clients.keywords"),
        },
      ],
    },
  },
  {
    path: "/404",
    name: "notFound",
    // redirect: { name: "404" },
    component: () => import("../views/404"),
  },
  {
    path: "*",
    name: "404",
    redirect: "/",
    // component: () => import('../views/404')
  },
];

function generateMeta(to) {
  document.title = to.meta.title;
  to.meta.metaTags
    .map((tagObj) => {
      const metaTag = document.createElement("meta");
      for (let key in tagObj) {
        metaTag.setAttribute(key, tagObj[key]);
      }
      metaTag.setAttribute("data-vue-router-controlled", "");
      return metaTag;
    })
    .forEach((tag) => document.head.appendChild(tag));
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  generateMeta(to);

  window.fbq("track", "ViewContent", {
    content_name: to.path,
  });

  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
  next();
});

export default router;
