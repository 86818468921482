import cookies from "js-cookie";
const lang = cookies.get("language") || 'ar'
if (lang === "ar") {
  import("@/assets/style/css/bootstrap-rtl.min.css");
  import("@/assets/fonts/noto-sans/stylesheet.css");
  import("@/assets/style/scss/ar/main.scss");
} else {
  import("@/assets/fonts/gelano/stylesheet.css");
  import("@/assets/style/scss/en/main.scss");
  //   import('@/assets/fonts/avenir/stylesheet.css')
}
