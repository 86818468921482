import Vue from "vue";
import debounce from "lodash/debounce";
Vue.directive("on-scroll", {
  inserted(el, binding) {
    const f = function(evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener("scroll", f);
      }
    };
    window.addEventListener("scroll", f);
  }
});

Vue.directive("scroll-top", {
  bind(el) {
    // let prevScroll = window.scrollY;
    window.addEventListener("scroll", function() {
      const nextScroll = this.scrollY;
      if (nextScroll > 180) {
        el.classList.add("show");
      } else {
        el.classList.remove("show");
      }
      //   prevScroll = nextScroll;
    });
  }
});
Vue.directive("scroll-section", {
  bind(el, binding) {
    window.addEventListener(
      "scroll",
      debounce(function() {
        const elOffset = el.offsetTop;

        if (this.scrollY >= elOffset) {
          el.classList.add("scrolled-done");
          const arrayClass = Array.from(el.classList);
          if (arrayClass.includes("scrolled-done")) {
            window.fbq("track", "ViewContent", {
              content_name: `${binding.value.route.fullPath} @ ${binding.value.name}`
            });
          } else {
            el.classList.remove("scrolled-done");
          }
        }
      }, 1000)
    );
  }
});
