<template>
  <section
    class="main_jumbotron"
    :class="{ 'main_jumbotron--gradient': isGradient }"
  >
    <b-container>
      <b-row class="justify-content-center">
        <b-col cols="12" md="7" class="main_jumbotron__info">
          <article v-if="mainHeaders.main">
            <!-- mainHeaders.main -->
            <h1 class="main-header" v-html="mainHeaders.main"></h1>
            <p class="secondary-header" v-html="mainHeaders.secondary"></p>
          </article>
          <section v-else-if="requestChecker">
            <content-placeholders :rounded="true">
              <content-placeholders-heading
                class="main_jumbotron__placeholder"
              />
            </content-placeholders>
          </section>

          <section class="main_jumbotron__actions">
            <Button
              class="button main_jumbotron-btn"
              :class="{
                'button--filled-white': isGradient,
                'button--filled-primary': !isGradient
              }"
              :text="$t('button.start_project')"
              :hasIcon="false"
              variant="light"
              @click="toggleOverlay"
            ></Button>

            <Button
              to="/about-us"
              class="button main_jumbotron-btn"
              :class="{
                'button--outline-white': isGradient,
                'button--outline-primary': !isGradient
              }"
              :text="$t('button.about_us')"
              variant="outline-light"
            ></Button>
          </section>
        </b-col>
        <b-col cols="12" md="5" class="main_jumbotron__illust--container">
          <!-- <section class="img-wrap  ratio-100">
          <div class="img-content"> -->
          <b-img class="main_jumbotron__illust--img" fluid :src="illustSrc" />
          <!-- </div>
        </div> -->
        </b-col>
      </b-row>
    </b-container>

    <!-- Overlay -->
    <global-overlay
      :show-overlay="showOverlay"
      @closeOverlay="showOverlay = false"
    />
    <!-- Overlay -->
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Jumbotron",
  props: {
    isGradient: {
      type: Boolean,
      default: true
    },
    mainHeaders: {
      type: Object,
      default: () => {
        return {
          main: "",
          //   "Custom App Design & Development that Transforms",
          secondary: ""
          // "Building highly-polished SaaS brand experiences that transform business and accelerate the speed of innovation."
        };
      }
    },
    illustSrc: {
      type: String,
      default: require("@/assets/imgs/jumbotron/jumbotron_illust.svg")
    }
  },
 
  computed: {
    ...mapGetters(["GlobalSettings", "settings"])
  },
  watch: {
    GlobalSettings: {
      handler() {
        if (this.$route.name !== "serviceDetails") {
          this.mainHeaders.main = this.settings("home_hero_title");
          this.mainHeaders.secondary = this.settings("home_hero_description");
          //   console.log("hoss", this.mainHeaders);
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      showOverlay: false,
      requestChecker: true
    };
  },
  mounted() {
    // to remove chemmer if there is no data
    setTimeout(() => {
      this.requestChecker = false;
    }, 10000);
  },
  methods: {
    toggleOverlay() {
      this.$store.dispatch("SetGlobalOverlay", {
        type: "service",
        isOpen: true
      });
    }
  }
};
</script>
