/* eslint-disable prefer-promise-reject-errors */
<template>
  <section>
    <input
      ref="file"
      type="file"
      multiple
      style="display:none"
      @change="onFileChange"
      v-on="$attrs"
    />

    <section class="file-upload">
      <b-btn
        class="file-upload__button"
        small
        text
        variant="link"
        title="upload Image"
        @click="$refs.file.click()"
        v-on="$attrs"
      >
        <i class="icon-upload"></i>
      </b-btn>
      <div class="file-upload__placeholder">
        <span class="file-upload__attach">{{ $t("label.attach_files") }}</span>
      </div>
    </section>
    <b-row v-if="ShowImgs.length > 0" class="w-100 mx-auto mt-4">
      <b-col v-for="(showImg, index) in ShowImgs" :key="index" sm="3" cols="6">
        <section class="img-wrap ratio-100 mb-3">
          <div class="img-content">
            <b-img
              v-if="pdfTypes[index] === false"
              :src="showImg"
              rounded
              fluid
              alt
            />
            <b-img
              v-if="pdfTypes[index] === true"
              :src="require('@/assets/imgs/pdf-icon.png')"
            />
          </div>
        </section>
        <i
          class="file-upload__delete "
          @click="deleteIamge($event, index, ShowImgs)"
          >&times;</i
        >
      </b-col>
    </b-row>
    <b-row v-else class="mt-4">
      <b-col sm="3" cols="6">
        <b-img
          fluid
          class="file-upload__img"
          :src="
            require('@/assets/imgs/illustrations/contactUs/placeholder.webp')
          "
        ></b-img>
      </b-col>
    </b-row>

    <!-- <v-dialog v-model="errorDialog" max-width="300">
      <v-card>
        <v-card-text class="subheading">{{ ImageErrorMsg }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat @click="errorDialog = false">Got it!</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <b-modal
      centered
      v-model="errorDialog"
      ref="my-modal"
      hide-footer
      hide-header
    >
      <div class="d-block text-center p-3" @click="hideModal">
        <h3 class="sub-heading">{{ ImageErrorMsg }}</h3>
      </div>
      <b-button
        class="m-3 mx-auto button"
        variant="outline-danger"
        block
        @click="hideModal"
        >{{ $t("button.close") }}</b-button
      >
    </b-modal>
  </section>
</template>

<script>
import { isValidImgType, isValidImgSize } from "@/constants/validate";

export default {
  props: {
    imgsUrl: {
      type: Array,
      default: () => []
    },
    width: {
      type: Number,
      default: null
    },
    height: {
      type: Number,
      default: null
    },
    maxHeight: {
      type: Number,
      default: 200
    },
    minHeight: {
      type: Number,
      default: 200
    },
    reset: {
      type: Boolean,
      default: false
    },
    fieldName: {
      type: String,
      default: ""
    },
    maxSize: {
      type: Number,
      default: 1
    },
    value: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      ShowImgs: [],
      imgsFiles: [],
      errorDialog: false,
      errorText: "",
      errorType: "",
      pdfTypes: []
    };
  },
  computed: {
    ImageErrorMsg() {
      if (this.errorType === "MIME") {
        return this.$t("validation.imageType");
      } else if (this.errorType === "SIZE") {
        return this.$t("validation.imageSize", { count: this.maxSize });
      } else if (this.errorType === "DIMENSIONS") {
        return this.$t("validation.imageDimensions", {
          width: this.width,
          height: this.height
        });
      } else if (this.errorType === "MAX_LENGTH") {
        return this.$t("validation.max_files_length", { count: 5 });
      } else {
        return "";
      }
    }
  },
  watch: {
    reset: {
      handler(newValue) {
        if (newValue === true) {
          this.resetImageFile();
        }
      }
    },
    imgsUrl: {
      handler(value) {
        this.ShowImgs = value.map(el => el.path);
      },
      immediate: true
    },
    errorDialog: {
      handler(value) {
        if (!value) {
          this.$refs.file.value = "";
        }
      },
      immediate: false
    }
  },
  methods: {
    hideModal() {
      this.$refs["my-modal"].hide();
      this.$refs.file.value = "";
    },
    deleteIamge(value, index, imgs) {
      this.change = false;
      const key = this.imgsFiles[this.imgsFiles.length];
      //   const { id } = this.imgsUrl[index] ? this.imgsUrl[index] : {};
      imgs.splice(index, 1);
      this.imgsFiles.splice(key, 1);
      this.pdfTypes.splice(index, 1);
      this.$refs.file.value = "";
    },

    onFileChange(e) {
      const imgFiles = e.target.files;

      if (imgFiles.length + this.ShowImgs.length > 5) {
        this.errorType = "MAX_LENGTH";
        this.errorDialog = true;
        this.$refs["my-modal"].show();

        return;
      }
      for (let i = 0; i < imgFiles.length; i++) {
        const fileReader = new FileReader(); // important

        if (this.validateType(imgFiles[i])) {
          if (this.validateSize(imgFiles[i])) {
            this.imgsFiles.push(imgFiles[i]);
            fileReader.addEventListener("load", () => {
              this.imgsFiles.map(attchment => {
                if (attchment.type === "application/pdf") {
                  attchment.icon = "pdf";
                }
              });

              this.ShowImgs.push(fileReader.result);
              this.pdfTypes = this.ShowImgs.map(el =>
                el.includes("application/pdf")
              );
            });
          }
        }

        fileReader.readAsDataURL(imgFiles[i]);
      }

      this.readImage(this.imgsFiles);
    },
    readImage(imgsFiles) {
      this.$emit("fileSelected", imgsFiles);
    },
    validateSize(file) {
      if (isValidImgSize(file.size, this.maxSize)) {
        return true;
      } else {
        this.errorType = "SIZE";
        this.errorDialog = true;
        this.$refs["my-modal"].show();
        return false;
      }
    },
    validateDimentions(file) {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = URL.createObjectURL(file);
        image.addEventListener(
          "load",
          () => {
            const width = image.naturalWidth;
            const height = image.naturalHeight;
            if (width !== this.width && height !== this.height) {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false);
            } else {
              resolve(true);
            }
          },
          false
        );
      });
    },
    validateType(file) {
      if (isValidImgType(file)) {
        return true;
      } else {
        this.errorType = "MIME";
        this.errorDialog = true;
        this.$refs["my-modal"].show();

        return false;
      }
    },
    resetImageFile() {
      this.imgsUrl = null;
      this.$refs.file.value = "";
    }
  }
};
</script>
