import { IndexData } from "@/helpers/apiMethods";

const services = {
  state: {
    services: []
  },
  mutations: {
    SET_SERVICES(state, payload) {
      state.services = [...payload];
    }
  },
  actions: {
    setServices: ({ commit }) => {
      IndexData({
        reqName: "services"
      })
        .then(res => {
          const { services } = res.data;
          commit("SET_SERVICES", services);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};

export default services;
